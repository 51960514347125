import React from 'react';
import Layout from '../components/Layout';

import TransparentIcon from '../images/svg/transparency.svg';
import CentilizedIcon from '../images/svg/centralized.svg';
import ImpactIcon from '../images/svg/stats.svg';

const Page = () => (
  <Layout fullgreen title="About">
    

    <div className="container">
      <div className="row text-white text-center py-5">
        <div className="col-12 col-md-6 offset-md-3">
          <h1 className="h2 mb-0">We are on the mission to change F&amp;B procurement</h1>
        </div>
      </div>
    </div>

    <div className="container vision">
      <div className="paper text-center">
        <p className="title-label">Our Vision</p>
        <p className="standout">
          Focus on serving your customers, not on your stocks.
        </p>
      </div>
    </div>

    <div className="container">
      <div className="row gx-5 my-5">
        <div className="col-md-5 values">
          <h2 className="h3 text-white mb-5 mt-3">Our Values</h2>
          <div className="row my-4">
            <div className="col-3">
              <CentilizedIcon />
            </div>
            <div className="col-9">
              <h3 className="h5">Customer-Centric</h3>
              <p>For us customer is in center of everything and we constantly looking for a new ways to serve him everyday better.</p>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-3">
              <TransparentIcon />
            </div>
            <div className="col-9">
              <h3 className="h5">Transparency</h3>
              <p>We strongly believe that opennes and honesty is a key for a long-term partnership that we build with all our customers and suppliers.</p>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-3">
              <ImpactIcon />
            </div>
            <div className="col-9">
              <h3 className="h5">Impactful</h3>
              <p>Our aim is to bring value for both both customers and suppliers.</p>
            </div>
          </div>
        </div>
        <div className="col-md-1 border-end border-white d-none d-md-flex"></div>
        <div className="col-md-5 text-white fs-5">
          <h2 className="h3 mb-5 mt-3">What We Do</h2>
          <p className="opacity-60">We set to change how the F&amp;B procurement works. We bring together restaurants, cafes, conviniece stores, supermarkets with new and well-known suppliers. Customers get a better choice of products, and suppliers get more orders.</p>
          <p className="opacity-60">We use Machine Learning to automate most of your regular procurement work, so you can focus on serving your customers and forget worries about your stocks.</p>
          <p className="opacity-60"></p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Page;